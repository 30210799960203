<template>
    <vs-card>
        <div slot="header">
          <h4 class="font-weight-bold card-title d-flex">
            {{$t('ACCOUNTING.ACC_SUM.HEADER')}} ({{CurrencyAsText(sum_brutto)}})
          </h4>

        </div>
        <div class="d-flex">
          <vs-list >
            <vs-list-item :title="$t('ACCOUNTING.ACC_SUM.GESAMT')" :subtitle="$t('ACCOUNTING.ACC_SUM.EXKL')"><vs-chip>{{sum_netto_txt}}</vs-chip></vs-list-item>
            <template v-for="(mwst,index) in sum_mwst">
              <vs-list-item :key="'mwst_'+index" :title="$t('ACCOUNTING.ACC_SUM.DAVON')" :subtitle="$t('ACCOUNTING.ACC_SUM.MWST1')+' '+mwst.mwst_val+$t('ACCOUNTING.ACC_SUM.MWST2')"><vs-chip>{{CurrencyAsText(mwst.betr)}}</vs-chip></vs-list-item>
            </template>
            <vs-list-item :title="$t('ACCOUNTING.ACC_SUM.GESAMT')" :subtitle="$t('ACCOUNTING.ACC_SUM.INKL')"><vs-chip color="primary" class="font-size-120p">{{sum_brutto_txt}}</vs-chip></vs-list-item>
          </vs-list>
          
        </div>
      </vs-card>
</template>
<script>

import helperMethods from '../../helper/staticFuncHelper';
import codeHelper from '../../helper/staticCodeHelper';
import enumHelper from '../../helper/enumHelper';

export default {
  name: "AccountingMemberSum",
  props:[],
  data: function (){
     return {
      members:[],
      sum_mwst:[],
      sum_netto:0,
      sum_brutto:0
     }
  }, 
  mounted () {
  },
  methods:{
    ...helperMethods,
    ...codeHelper,
    ...enumHelper,
    SetMembers(mem){
      this.members = [];
      this.members = mem;
    },
    addMwstVal(member){
      var found = false;
      if(member.mwst_val != 0)
      {
        for(var i = 0; i < this.sum_mwst.length; i++)
        {
          if(this.sum_mwst[i].mwst_val == member.mwst_val)
          {
            found = true;
            this.sum_mwst[i].betr += (member.brutto - member.netto) * member.amount;
            break;
          }
        }
        if(found == false)
        {
          var mem = {mwst_val:member.mwst_val,betr: (member.brutto - member.netto) * member.amount};
          this.sum_mwst.push(mem);
        }
      }
    },
    CurrencyAsText(betr)
    {
      return this.DecimalToString(betr,this.currency_kuerzel,this.GetLocalFormatOfLanguage(this.language_fk));
    },
    CalcSumNetto(){
      var sum = 0;
      
      if(this.IsNotNull(this.members))
      {
        this.sum_mwst=[];
        this.members.forEach(member => {
          if(this.IsNotNull(member.sum_netto) && this.IsNotNull(member.partnerdiscount_netto))
          {
            sum += (member.sum_netto - member.partnerdiscount_netto);
            this.addMwstVal(member);
          }
            
        });
      }

      this.sum_netto = sum;
    },
    CalcSumBrutto(){
      var sum = 0;
      if(this.IsNotNull(this.members))
      {
        this.members.forEach(member => {
          if(this.IsNotNull(member.sum_netto) && this.IsNotNull(member.partnerdiscount_netto))
          {
            sum += (member.sum_brutto - member.partnerdiscount_brutto);
          }
            
        });
      }
      this.sum_brutto = sum;
    }
  },
  computed:{

    sum_netto_txt(){
      return this.CurrencyAsText(this.sum_netto);
    },
    sum_brutto_txt(){
      return this.CurrencyAsText(this.sum_brutto);
    },
    currency_kuerzel(){
      var retVal = 'EUR';

      if(this.members.length > 0)
      {
        retVal = this.members[0].currency_kuerzel;
      }

      return retVal;
    },
    language_fk(){
      var retVal = 1;
      if(this.members.length > 0)
      {
        retVal = this.members[0].language_fk;
      }      
      return retVal;
    }
  },
  watch:{
    members(){
      this.CalcSumNetto();
      this.CalcSumBrutto();
    }
  }
};
</script>